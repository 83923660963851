import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import illustration from "./../../assets/images/float-gift.png";
import logo from "./../../assets/icons/logo-big.svg";
import PageLayout from "examples/LayoutContainers/PageLayout";
import TLinkApi from "../../services/tlink.api";
import { Controller, useForm } from "react-hook-form";

import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";

import "./member.css";

function MemberDetails() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gender: "",
      dob: "",
      zipCode: "",
      highestEducation: "",
      houseHoldIncome: "",
      ethnicity: "",
    },
  });

  const navigate = useNavigate();

  const save = async (payload) => {
    setIsLoading(true);
    try {
      const { message } = await TLinkApi.put("/user/demographic", payload);
      if (message === "created") {
        enqueueSnackbar("verify Your Email", {
          variant: "success",
        });
        setIsLoading(false);
        navigate("/profile");
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      // alert("Login failed. Please check your credentials!");
    }
  };

  return (
    <PageLayout
      gradientBg={
        "linear-gradient(315.54deg, #FF4646 -5.94%, #FFCDCD 21.57%, #FFFFFF 58.36%)"
      }
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MDBox px={1} width="100%" height="100vh" mx="auto" position="relative">
        <a href="https://voqall.com/" style={{ cursor: "pointer" }}>
          <img
            src={logo}
            style={{ height: "24px", position: "absolute", margin: "32px" }}
            alt="logo"
          />
        </a>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-around"
          height="100%"
        >
          <Grid item xs={12} s={12} md={12} lg={6}>
            <form onSubmit={handleSubmit(save)} noValidate>
              <MDBox
                m={5}
                mt={10}
                p={5}
                style={{
                  background: "rgba(238, 238, 238, 1)",
                  borderRadius: "25px",
                }}
              >
                <Grid>
                  <p>Kindly, enter the following details to proceed.</p>
                  <hr />

                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="gender"
                        control={control}
                        rules={{
                          required: "Gender is required!",
                        }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <label className="labelStyle">
                              <span style={{ color: "red" }}>*</span> Gender
                            </label>
                            <FormControl
                              fullWidth
                              required
                              error={errors?.gender ? true : false}
                            >
                              <Select
                                value={value}
                                onChange={onChange}
                                sx={{
                                  backgroundColor: "rgba(226, 226, 226, 1)",
                                }}
                              >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Intersex/ambiguous">
                                  Intersex/ambiguous
                                </MenuItem>
                                <MenuItem value="Prefer not to answer">
                                  Prefer not to answer
                                </MenuItem>
                              </Select>
                              {errors?.gender && (
                                <FormHelperText>
                                  {errors?.gender
                                    ? errors.gender.message
                                    : null}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        name="dob"
                        control={control}
                        rules={{
                          required: "Date of Birth is required!",
                        }}
                        render={({ field }) => (
                          <>
                            <label className="labelStyle">
                              <span style={{ color: "red" }}>*</span> Date of
                              Birth
                            </label>
                            <MDInput
                              {...field}
                              fullWidth
                              required
                              autoComplete="off"
                              variant="outlined"
                              type="Date"
                              error={errors?.dob ? true : false}
                              helperText={
                                errors?.dob ? errors.dob.message : null
                              }
                            />
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        name="zipCode"
                        control={control}
                        rules={{
                          required: "Zip Code is required!",
                        }}
                        render={({ field }) => (
                          <>
                            <label className="labelStyle">
                              <span style={{ color: "red" }}>*</span> Zip Code
                            </label>
                            <MDInput
                              {...field}
                              fullWidth
                              autoComplete="off"
                              variant="outlined"
                              type="Number"
                              error={errors?.zipCode ? true : false}
                              helperText={
                                errors?.zipCode ? errors.zipCode.message : null
                              }
                            />
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        name="ethnicity"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <label className="labelStyle">Ethnicity</label>
                            <FormControl fullWidth>
                              <Select
                                value={value}
                                onChange={onChange}
                                sx={{
                                  backgroundColor: "rgba(226, 226, 226, 1)",
                                }}
                              >
                                <MenuItem value="White or Caucasian (non-Hispanic)">
                                  White or Caucasian (non-Hispanic)
                                </MenuItem>
                                <MenuItem value="Black or African-American">
                                  Black or African-American
                                </MenuItem>
                                <MenuItem value="Asian or Pacific Islander">
                                  Asian or Pacific Islander
                                </MenuItem>
                                <MenuItem value="Hispanic or Latino">
                                  Hispanic or Latino
                                </MenuItem>
                                <MenuItem value="American Indian or Alaska Native">
                                  American Indian or Alaska Native
                                </MenuItem>
                                <MenuItem value="Mixed Racial Background">
                                  Mixed Racial Background
                                </MenuItem>
                                <MenuItem value="Prefer not to answer">
                                  Prefer not to answer
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        name="highestEducation"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <label className="labelStyle">
                              Highest Level of Education
                            </label>
                            <FormControl fullWidth>
                              <Select
                                value={value}
                                onChange={onChange}
                                sx={{
                                  backgroundColor: "rgba(226, 226, 226, 1)",
                                }}
                              >
                                <MenuItem value="3rd Grade or less">
                                  3rd Grade or less
                                </MenuItem>
                                <MenuItem value="Middle School - Grades 4 - 8">
                                  Middle School - Grades 4 - 8
                                </MenuItem>
                                <MenuItem value="btCompleted some high schoolech">
                                  Completed some high school
                                </MenuItem>
                                <MenuItem value="High school graduate">
                                  High school graduate
                                </MenuItem>
                                <MenuItem value="Other post high school vocational training">
                                  Other post high school vocational training
                                </MenuItem>
                                <MenuItem value="Completed some college, but no degree">
                                  Completed some college, but no degree
                                </MenuItem>
                                <MenuItem value="Associate Degree">
                                  Associate Degree
                                </MenuItem>
                                <MenuItem value="College Degree (such as B.A., B.S.)">
                                  College Degree (such as B.A., B.S.)
                                </MenuItem>
                                <MenuItem value="Completed some graduate, but no degree">
                                  Completed some graduate, but no degree
                                </MenuItem>
                                <MenuItem value="Masters degree">
                                  Masters degree
                                </MenuItem>
                                <MenuItem value="Doctorate degree">
                                  Doctorate degree
                                </MenuItem>
                                <MenuItem value="None of the above">
                                  None of the above
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="houseHoldIncome"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <label className="labelStyle">
                              Household Income
                            </label>
                            <FormControl fullWidth>
                              <Select
                                value={value}
                                onChange={onChange}
                                sx={{
                                  backgroundColor: "rgba(226, 226, 226, 1)",
                                }}
                              >
                                <MenuItem value="Less than $5,000">
                                  Less than $5,000
                                </MenuItem>
                                <MenuItem value="$5,000 to $9,999">
                                  $5,000 to $9,999
                                </MenuItem>
                                <MenuItem value="$10,000 to $14,999">
                                  $10,000 to $14,999
                                </MenuItem>
                                <MenuItem value="$20,000 to $24,999">
                                  $20,000 to $24,999
                                </MenuItem>
                                <MenuItem value="$25,000 to $29,999">
                                  $25,000 to $29,999
                                </MenuItem>
                                <MenuItem value="$30,000 to $34,999">
                                  $30,000 to $34,999
                                </MenuItem>
                                <MenuItem value="$35,000 to $39,999">
                                  $35,000 to $39,999
                                </MenuItem>
                                <MenuItem value="$40,000 to $44,999">
                                  $40,000 to $44,999
                                </MenuItem>
                                <MenuItem value="$45,000 to $49,999">
                                  $45,000 to $49,999
                                </MenuItem>
                                <MenuItem value="$50,000 to $54,999">
                                  $50,000 to $54,999
                                </MenuItem>
                                <MenuItem value="$55,000 to $59,999">
                                  $55,000 to $59,999
                                </MenuItem>
                                <MenuItem value="$60,000 to $64,999">
                                  $60,000 to $64,999
                                </MenuItem>
                                <MenuItem value="$65,000 to $69,999">
                                  $65,000 to $69,999
                                </MenuItem>
                                <MenuItem value="$70,000 to $74,999">
                                  $70,000 to $74,999
                                </MenuItem>
                                <MenuItem value="$75,000 to $79,999">
                                  $75,000 to $79,999
                                </MenuItem>
                                <MenuItem value="$80,000 to $84,999">
                                  $80,000 to $84,999
                                </MenuItem>
                                <MenuItem value="$85,000 to $89,999">
                                  $85,000 to $89,999
                                </MenuItem>
                                <MenuItem value="$90,000 to $94,999">
                                  $90,000 to $94,999
                                </MenuItem>
                                <MenuItem value="$95,000 to $99,999">
                                  $95,000 to $99,999
                                </MenuItem>
                                <MenuItem value="$100,000 to $124,999">
                                  $100,000 to $124,999
                                </MenuItem>
                                <MenuItem value="$125,000 to $149,999">
                                  $125,000 to $149,999
                                </MenuItem>
                                <MenuItem value="$150,000 to $174,999">
                                  $150,000 to $174,999
                                </MenuItem>
                                <MenuItem value="$175,000 to $199,999">
                                  $175,000 to $199,999
                                </MenuItem>
                                <MenuItem value="$200,000 to $249,999">
                                  $200,000 to $249,999
                                </MenuItem>
                                <MenuItem value="$250,000 and above">
                                  $250,000 and above
                                </MenuItem>
                                <MenuItem value="Prefer not to answer">
                                  Prefer not to answer
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <MDBox
                    pt={4}
                    pb={2}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="10px"
                  >
                    <MDButton
                      variant="gradient"
                      style={{
                        background: "rgba(255, 176, 176, 1)",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      &nbsp;cancel
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      style={{
                        background: "rgba(255, 51, 51, 1)",
                        color: "white",
                      }}
                      type="submit"
                    >
                      Continue
                    </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>
            </form>
          </Grid>
          <Grid
            item
            alignItems="center"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <div className="image-container">
              <img
                src={illustration}
                style={{ maxHeight: "95vh" }}
                alt="logo"
              />
              <div className="text-overlay">
                <h2>Join Voqall</h2>
                <p>Earn rewards for what you share.</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default MemberDetails;
