import { Grid } from "@mui/material";
import React from "react";
import Amazon from "assets/images/logos/Amazon.png";
import AmazonLogo from "assets/images/logos/AmazonLogo.png";
import Starbuck from "assets/images/logos/Starbuck.png";
import PayPal from "assets/images/logos/PayPal.png";
import CardCompotent from "../../../src/pages/redeem/Compotents/CardCompotent";
import DetailsPoints from "../../../src/pages/redeem/Compotents/DetailsPoints";
import { SummaryData } from "data/survey";
import MDBox from "components/MDBox";

function RedeemPoints() {
  var summary = SummaryData();
  return (
    <MDBox>
      <Grid container spacing={2} p={2} mt={2}>
        <Grid item xs={12} md={12} lg={9}>
          <Grid container spacing={2}>
            <CardCompotent
              CompanyImage={Amazon}
              Name={"Amazon"}
              CompanyLogo={AmazonLogo}
              value={"1000"}
            />
            <CardCompotent
              CompanyImage={Starbuck}
              Name={"Starbuck"}
              CompanyLogo={AmazonLogo}
              value={"1000"}
            />
            <CardCompotent
              CompanyImage={PayPal}
              Name={"PayPal"}
              CompanyLogo={AmazonLogo}
              value={"1000"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <DetailsPoints summary={summary} />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default RedeemPoints;
