import React from "react";
import MDBox from "components/MDBox";
import { Backdrop, CircularProgress, Divider, Grid } from "@mui/material";
import StatsCardCompotent from "./components/statsCard/statsCard";
import MDTypography from "components/MDTypography";
import SurveyCard from "./components/surveyCard";
import { LiveSurveys, SummaryData } from "data/survey";
import TotalPointsIcon from "../../../src/assets/icons/totalPoints.svg";
import TotalSurveyIcon from "../../../src/assets/icons/totalSurveys.svg";
import SurveyCompletedIcon from "../../../src/assets/icons/surveyCompleted.svg";
import PointsRedeemedIcon from "../../../src/assets/icons/pointsRedeemed.svg";
import { useOutletContext } from "react-router-dom";

function Dashboard() {
  const { currentUser } = useOutletContext();
  const { list, isLoading } = LiveSurveys(currentUser?.userid);
  const summary = SummaryData();
  return (
    <MDBox>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1} pl={"5px"} mt={2}>
        <StatsCardCompotent
          colour={
            "linear-gradient(286.07deg, #FF8A8A 0%, rgba(255, 215, 215, 0.65) 100%)"
          }
          text={"Total Points"}
          textColor="#FF3333"
          icon={TotalPointsIcon}
          value={summary?.totalPoints ? summary.totalPoints : 0}
        />

        <StatsCardCompotent
          colour={
            "linear-gradient(285.75deg, #FFAA83 3.97%, rgba(253, 222, 208, 0.65) 96.49%)"
          }
          text={"Total Surveys"}
          textColor="#ED6E33"
          icon={TotalSurveyIcon}
          value={summary?.totatSurveys ? summary.totatSurveys : 0}
        />
        <StatsCardCompotent
          colour={"linear-gradient(286.69deg, #FFD582 -0.93%, #FFF5E1 100%)"}
          text={"Completed Surveys"}
          textColor="#FFA800"
          icon={SurveyCompletedIcon}
          value={summary?.surveysCompleted ? summary.surveysCompleted : 0}
        />
        <StatsCardCompotent
          colour={
            "linear-gradient(287.05deg, #BEA7FE -1.51%, rgba(235, 228, 255, 0.65) 100%)"
          }
          text={"Points Redeemed"}
          textColor="#7356BF"
          icon={PointsRedeemedIcon}
          value={summary?.pointsRedeemed ? summary.pointsRedeemed : 0}
        />
      </Grid>
      <Divider mt={1} />

      <MDTypography
        variant="h5"
        mt={1}
        fontWeight="light"
        mx={3}
        sx={{
          color: "black",
          fontWeight: 500,
        }}
      >
        New Survey Opportunities
      </MDTypography>

      <Grid container pl={"5px"} mt={1}>
        {list.length > 0 ? (
          list.map((item, i) => {
            return <SurveyCard {...item} key={i} />;
          })
        ) : (
          <MDTypography
            variant="h5"
            mt={1}
            fontWeight="light"
            mx={3}
            sx={{
              color: "black",
              fontWeight: 500,
            }}
          >
            No Survey Available
          </MDTypography>
        )}
      </Grid>
    </MDBox>
  );
}

export default Dashboard;
