import { Grid } from "@mui/material";
import React from "react";
import MDButton from "components/MDButton";
import "./RedeemCard.css";

function CardCompotent(props) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <div className="card">
        <div className="CompanyImg">
          <img
            src={props.CompanyImage}
            alt=""
            style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
          />
        </div>

        <div className="VoucherDetail">
          <div className="CompanyLogo">
            <img src={props.CompanyLogo} width="30px" height="30px" alt="" />
          </div>
          <div className="Details">
            <div className="Details1">
              <div className="VoucherName">{props.Name}</div>
              <div className="VoucherPoints">{`Redeem for ${props.value}`}</div>
            </div>
            <div className="RedeemButton">
              <MDButton className="surveyBtn" color="secondary">
                Redeem
              </MDButton>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default CardCompotent;
