import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useOutletContext } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import RobotAvatar from "assets/images/logos/robot_elink.avif";
import { Avatar, Backdrop, CircularProgress, Divider } from "@mui/material";
import UserDetailForm from "../UserDeatils";
import UserDetailsTable from "../UserDeatils/UserDetailsTable";
import GetProfileData from "examples/hooks/profileData/GetProfileData";

function Header() {
  const { currentUser } = useOutletContext();
  const [isEditable, setIsEditable] = useState(false);
  const { list, isLoading, getProfile } = GetProfileData();

  useEffect(() => {
    if (Object.keys(list).length > 0) {
      if (list.gender && list.dob) {
        setIsEditable(false);
      } else {
        setIsEditable(true);
      }
    }
  }, [list]);

  return (
    <MDBox position="relative" mx={3}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        mt={7}
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10.75rem"
        borderRadius="xl"
        top="15px"
        sx={{
          background:
            " linear-gradient(268.9deg, #FFCBCB 28.71%, rgba(246, 244, 231, 0.8) 71.36%, #EEEEEE 103.69%)",
          overflow: "hidden",
        }}
      />
      <MDBox
        sx={{
          position: "relative",
          mt: -14,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item>
            {/* <Avatar {...stringAvatar(firstName)} style={{marginTop:"40px"}} sx={{  bgcolor: "red" ,width: 148, height: 148 }}/>  */}
            <Avatar
              alt="Loading..."
              src={RobotAvatar}
              style={{ height: "150px", width: "150px", marginTop: "40px" }}
            />
          </Grid>
          <Grid item>
            <MDBox height="100px" mt={0.5} py={13} lineHeight={1}>
              <MDTypography
                variant="h5"
                fontWeight="medium"
                style={{
                  height: "36px",
                  fontFamily: "Proxima Nova",
                  fontStyle: " normal",
                  fontWeight: "500",
                  fontSize: "28px",
                  lineHeight: "36px",
                }}
              >
                {currentUser && currentUser.firstname && currentUser.lastname
                  ? currentUser?.firstname + " " + currentUser?.lastname
                  : ""}
              </MDTypography>
              <MDTypography
                variant="button"
                color="text"
                fontWeight="regular"
                style={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "28px",
                }}
              >
                {currentUser?.email ? currentUser?.email : ""}
              </MDTypography>
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="136px"
                height="32px"
                gap={0.5}
                mt={0.5}
              >
                {/* <img
                  src={Flag}
                  alt="flag-image"
                  style={{ width: "32px", height: "32px" }}
                /> */}
                <MDTypography
                  style={{
                    display: "flex",
                    fontFamily: "Proxima Nova",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "16px",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                  }}
                >
                  {currentUser?.country ? currentUser?.country : ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Divider />
      {!isEditable ? (
        <UserDetailsTable setIsEditable={setIsEditable} list={list} />
      ) : (
        <UserDetailForm
          setIsEditable={setIsEditable}
          list={list}
          getProfile={getProfile}
        />
      )}
    </MDBox>
  );
}

export default Header;
