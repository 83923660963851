import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
import MDButton from "components/MDButton";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

function Footer() {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const { size } = typography;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setToggle(false);
    }, 500);
  };

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        p={1.5}
      >
        If you don’t wish to participate further in any of the survey
        opportunities and wish to un-subscribe, please click on{" "}
        <MDButton
          variant="text"
          fontWeight="regular"
          color="primary"
          cursor="pointer"
          onClick={handleClickOpen}
        >
          Unsubscribe
        </MDButton>
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Unsubscribe?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {toggle ? (
              <>
                We have received your request to unsubscribe from Voqall's panel
                and are taking the necessary steps to fulfill your request. Once
                the process is complete, you will receive a confirmation email
                regarding the same
              </>
            ) : (
              <>
                We are sorry to see you leave so soon , are you sure you wish to
                unsubscribe
              </>
            )}
          </DialogContentText>
        </DialogContent>
        {toggle ? (
          <DialogActions>
            <MDButton
              color="primary"
              fontWeight="regular"
              cursor="pointer"
              onClick={handleClose}
            >
              ok
            </MDButton>
          </DialogActions>
        ) : (
          <DialogActions>
            <MDButton
              fontWeight="regular"
              color="dark"
              variant="text"
              cursor="pointer"
              onClick={() => {
                setToggle(true);
              }}
            >
              Yes
            </MDButton>
            <MDButton
              color="primary"
              fontWeight="regular"
              cursor="pointer"
              onClick={handleClose}
            >
              No
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
    </MDBox>
  );
}

export default Footer;
