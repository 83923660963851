import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "./../../components/MDBox";
import MDTypography from "./../../components/MDTypography";
import MDInput from "./../../components/MDInput";
import MDButton from "./../../components/MDButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Axios from "axios";
import Auth from "./../../services/auth";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import illustration from "./../../assets/images/illustration1.png";
import logo from "./../../assets/icons/logo-big.svg";
import PageLayout from "examples/LayoutContainers/PageLayout";
import TLinkApi from "services/tlink.api";
import { Backdrop, CircularProgress } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

function SignIn() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { email: "", password: "" },
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const save = async (payload) => {
    setIsLoading(true);
    try {
      const { data, status } = await Axios.post(
        TLinkApi.baseUrl + "/login",
        payload
      );
      if (status === 200) {
        Auth.saveAuthorizationToken(data.token);
        enqueueSnackbar("Logged in", {
          variant: "success",
        });
        setIsLoading(false);
        navigate("/dashboard");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err?.response.data.message);
      enqueueSnackbar(err?.response.data.message, {
        variant: "error",
      });

      // alert("Login failed. Please check your credentials!");
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout
        gradientBg={
          "linear-gradient(315.54deg, #FF4646 -5.94%, #FFCDCD 21.57%, #FFFFFF 58.36%)"
        }
      >
        <a href="https://voqall.com/" style={{ cursor: "pointer" }}>
          <img
            src={logo}
            style={{ height: "24px", position: "absolute", margin: "32px" }}
            alt="logo"
          />
        </a>

        <MDBox px={1} width="100%" height="100vh" mx="auto">
          <Grid
            container
            spacing={1}
            justifyContent="space-around"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={11} sm={9} md={5} lg={5} xl={3.5}>
              <MDBox mb={1} textAlign="center">
                <MDTypography
                  variant="h2"
                  fontWeight="medium"
                  color="text"
                  mt={1}
                  sx={{
                    color: "black",
                  }}
                >
                  Welcome back!
                </MDTypography>
                <MDTypography
                  variant="h6"
                  fontWeight="light"
                  color="text"
                  sx={{ color: "#333333" }}
                  mt={1}
                >
                  Please enter your details to sign in.
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <form onSubmit={handleSubmit(save)} noValidate>
                  <MDBox mb={2}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type="email"
                          {...register("email", {
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                          variant="outlined"
                          placeholder="Enter Your Email"
                          error={errors?.email ? true : false}
                          helperText={
                            errors?.email ? errors.email.message : null
                          }
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: "Password is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Your Password"
                          variant="outlined"
                          error={errors?.password ? true : false}
                          helperText={
                            errors?.password ? errors.password.message : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox
                    mt={1}
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <span
                      style={{ fontSize: "15px", cursor: "pointer" }}
                      onClick={() => {
                        navigate("/forgetPassword");
                      }}
                    >
                      Forgot password ?
                    </span>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      type="submit"
                      fullWidth
                    >
                      Sign In
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" sx={{ color: "#000000" }}>
                      Don&apos;t have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/signUp"
                        variant="button"
                        fontWeight="medium"
                        textGradient
                        sx={{ color: "#000000" }}
                      >
                        Sign up
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </form>
              </MDBox>
            </Grid>
            <Grid
              item
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <img
                src={illustration}
                style={{ maxHeight: "95vh" }}
                alt="logo"
              />
            </Grid>
          </Grid>
        </MDBox>
      </PageLayout>
    </>
  );
}

export default SignIn;
