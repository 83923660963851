import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MDBox from "components/MDBox";
import "./index.css";
import NotificationItem from "examples/Items/NotificationItem";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import { Backdrop, CircularProgress, Divider, Tooltip } from "@mui/material";
import { MenuOpen } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import MDButton from "components/MDButton";
import TLinkApi from "services/tlink.api";

function DashboardNavbar({ absolute, light, isMini, currentUser }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  let { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const HandleLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleCloseMenu = () => setOpenMenu(false);
  const [isLoading, setIsLoading] = useState(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  const handleResendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { status } = await TLinkApi.get("/user/Resendemail/Verification/");
      if (status === 200) {
        enqueueSnackbar("Check your Email", { variant: "success" });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err?.responce.data.error);
      enqueueSnackbar("Failed to Send a email", { variant: "error" });
      setIsLoading(false);
    }
  };
  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        {currentUser?.is_email_verified === false && (
          <Stack sx={{ width: "100%" }} spacing={5}>
            <Alert severity="error">
              <strong
                style={{ fontSize: "12px", color: "red", marginRight: "20px" }}
              >
                Your email is not verified yet. To have a seamless access ,
                please validate the link sent to your registered email id. If
                you didn’t receive any verification email, click on Resend Email
              </strong>
              <MDButton
                color="info"
                mx={10}
                variant="gradient"
                onClick={handleResendEmail}
              >
                Resend Email
              </MDButton>
            </Alert>
          </Stack>
        )}

        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <div className="headerName" style={{}}>
              {route[route.length - 1].charAt(0).toUpperCase() +
                route[route.length - 1].slice(1)}
              <Divider
                sx={{ margin: "0px 0px 0px 0px" }}
                className="headerHL"
              />
            </div>
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox
                pr={1}
                fontSize="12px"
                display="flex"
                alignItems="flex-end"
                flexDirection="row"
                justifyContent="flex-end"
                gap="4px"
              >
                <Tooltip title="Logout">
                  <IconButton onClick={HandleLogOut}>
                    <LogoutIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <IconButton>
                  <NotificationsActiveIcon fontSize="medium" />
                </IconButton>
                <IconButton
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <MenuOpen sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </MenuOpen>
                </IconButton>
              </MDBox>
              {renderMenu()}
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
