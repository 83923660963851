import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useMaterialUIController } from "context";
import { SnackbarProvider } from "notistack";
import theme from "assets/theme";
import SignIn from "./pages/auth/signIn";
import SignUp from "./pages/auth/signUp";
import { CssBaseline } from "@mui/material";
import AuthOutlet from "pages/auth/components/AuthOutlet";
import Dashboard from "pages/dashboard";
import PointsDetails from "pages/points";
import RedeemPoints from "pages/redeem";
import ProfileSetting from "pages/profile";
import VerifyToken from "pages/Verify";
import ForgotPassword from "pages/ForgotPassword";
import ConfirmPassword from "pages/NewPassword";
import ProfileSurvey from "pages/profileSurvey";
import MemberDetails from "pages/MemberDetails";

function App() {
  const [controller] = useMaterialUIController();
  const { direction, darkMode } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? theme : theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={2000}
      >
        <CssBaseline />
        <Routes>
          <Route element={<AuthOutlet />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/survey" element={<ProfileSurvey />} />
            <Route path="/points" element={<PointsDetails />} />
            <Route path="/redeem" element={<RedeemPoints />} />
            <Route path="/profile" element={<ProfileSetting />} />

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/member" element={<MemberDetails />} />
          <Route path="/verify" element={<VerifyToken />} />
          <Route path="/forgetPassword" element={<ForgotPassword />} />
          <Route path="/confirmPassword" element={<ConfirmPassword />} />
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
