import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "./../../components/MDBox";
import MDTypography from "./../../components/MDTypography";
import MDInput from "./../../components/MDInput";
import MDButton from "./../../components/MDButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Auth from "./../../services/auth";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import illustration from "./../../assets/images/illustration1.png";
import logo from "./../../assets/icons/logo-big.svg";
import PageLayout from "examples/LayoutContainers/PageLayout";

import { Backdrop, CircularProgress } from "@mui/material";
import TLinkApi from "../../services/tlink.api";
import { Controller, useForm } from "react-hook-form";

function SignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsloading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm({
    defaultValues: { email: "", password: "", firstName: "", lastName: "" },
  });

  const email = watch("email");
  const password = watch("password");
  const firstName = watch("firstName");
  const lastName = watch("lastName");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const save = async (payload) => {
    setIsloading(true);
    try {
      const { data, status } = await TLinkApi.post("/signup", payload);
      if (status === 200) {
        enqueueSnackbar("verify Your Email", {
          variant: "success",
        });
        Auth.saveAuthorizationToken(data.token);
        setIsloading(false);
        navigate("/member");
      }
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsloading(false);
      // alert("Login failed. Please check your credentials!");
    }
  };
  const [isChecked, setIsChecked] = useState(false);

  function handleCheckBoxChange(e) {
    setIsChecked(e.target.checked);
  }

  return (
    <PageLayout
      gradientBg={
        "linear-gradient(315.54deg, #FF4646 -5.94%, #FFCDCD 21.57%, #FFFFFF 58.36%)"
      }
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MDBox px={1} width="100%" height="100vh" mx="auto" position="relative">
        <a href="https://voqall.com/" style={{ cursor: "pointer" }}>
          <img
            src={logo}
            style={{ height: "24px", position: "absolute", margin: "32px" }}
            alt="logo"
          />
        </a>
        <Grid
          container
          spacing={1}
          justifyContent="space-around"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} mt={5} sm={9} md={5} lg={5} xl={3.5}>
            <MDBox mb={1} textAlign="center">
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="text"
                mt={1}
                sx={{
                  color: "black",
                }}
              >
                Hello there!
              </MDTypography>
              <MDTypography
                variant="h6"
                fontWeight="light"
                color="text"
                sx={{ color: "#333333" }}
                mt={1}
              >
                Please enter your details to sign Up.
              </MDTypography>
            </MDBox>
            <MDBox pt={2} pb={3} px={3}>
              <form onSubmit={handleSubmit(save)} noValidate>
                <MDBox mb={2}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: "First Name is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="text"
                        placeholder="First Name *"
                        variant="outlined"
                        error={errors?.firstName ? true : false}
                        helperText={
                          errors?.firstName ? errors.firstName.message : null
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: "Last Name is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="text"
                        placeholder="Last Name *"
                        variant="outlined"
                        error={errors?.lastName ? true : false}
                        helperText={
                          errors?.lastName ? errors.lastName.message : null
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="email"
                        {...register("email", {
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              "Entered value does not match email format",
                          },
                        })}
                        variant="outlined"
                        placeholder="Enter Your Email *"
                        error={errors?.email ? true : false}
                        helperText={errors?.email ? errors.email.message : null}
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password *"
                        variant="outlined"
                        error={errors?.password ? true : false}
                        helperText={
                          errors?.password ? errors.password.message : null
                        }
                        {...register("password", {
                          required: "Password required",
                          minLength: {
                            value: 8,
                            message:
                              "Must contain at least one uppercase letter. Must contain at least one lowercase letter. Must contain at least one number. Must contain at least one special character.",
                          },
                          validate: (value) => {
                            return (
                              [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every(
                                (pattern) => pattern.test(value)
                              ) ||
                              "Must contain at least one uppercase letter. Must contain at least one lowercase letter. Must contain at least one number. Must contain at least one special character."
                            );
                          },
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </MDBox>
                <MDBox
                  mt={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div style={{ fontSize: "13px" }}>
                    <div style={{ paddingTop: "3px" }}>
                      Please read the Privacy Policy
                      <strong>
                        {" "}
                        carefully and give consent within it to the mandatory
                        requirements before we can provide you with market
                        research surveys{" "}
                        <a href="https://voqall.com/privacy-policy/">
                          <span style={{ color: "blue" }}> Privacy</span>
                        </a>{" "}
                        Policy{" "}
                        <a href="https://voqall.com/privacy-policy/">
                          <span style={{ color: "blue" }}>
                            {" "}
                            Sensitive Personal Data Policy
                          </span>
                        </a>
                      </strong>
                    </div>
                    <MDBox
                      mt={2}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckBoxChange}
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                      <div style={{ fontSize: "12px", paddingLeft: "10px" }}>
                        <span>
                          I have read and agree to{" "}
                          <a
                            href="https://voqall.com/privacy-policy/"
                            style={{ fontWeight: "900" }}
                          >
                            Terms and Conditions
                          </a>{" "}
                          contained in the Privacy Policy.
                        </span>
                      </div>
                    </MDBox>
                  </div>
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={
                      email === "" ||
                      password === "" ||
                      firstName === "" ||
                      lastName === "" ||
                      isChecked === false
                    }
                  >
                    Sign Up
                  </MDButton>
                </MDBox>
              </form>
              <MDBox
                mt={2}
                mb={1}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                <p style={{ color: "#000000", fontSize: "14px" }}>
                  Already Have an Account?
                  <strong style={{ fontSize: "14px", color: "red" }}>
                    {" "}
                    Back to Login
                  </strong>
                </p>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid
            item
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <img src={illustration} style={{ maxHeight: "95vh" }} alt="logo" />
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default SignUp;
