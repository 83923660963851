import dashboardIcon from "./assets/icons/dashboard.svg";
import pointsIcon from "./assets/icons/points.svg";
import redeemPointsIcon from "./assets/icons/redeemPoints.svg";
import settingsIcon from "./assets/icons/settings.svg";
import privacyIcon from "./assets/icons/privacy.svg";
import supportIcon from "./assets/icons/support.svg";

import activeDashboard from "./assets/activeIcon/activeDashboard.png";
import activeMembership from "./assets/activeIcon/activeMembership.png";
import activeScoreboard from "./assets/activeIcon/activeScoreboard.png";
import activeSetting from "./assets/activeIcon/activeSetting.png";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: dashboardIcon,
    route: "/dashboard",
    activeIcon: activeDashboard,
  },
  {
    type: "collapse",
    name: "Reward Points",
    key: "points",
    icon: pointsIcon,
    route: "/points",
    activeIcon: activeScoreboard,
  },
  {
    type: "collapse",
    name: "Redemption Details",
    key: "redeem",
    icon: redeemPointsIcon,
    route: "/redeem",
    activeIcon: activeMembership,
  },
  {
    type: "collapse",
    name: "Profile Settings",
    key: "profile",
    icon: settingsIcon,
    route: "/profile",
    activeIcon: activeSetting,
  },
];

const bottomRoutes = [
  {
    type: "divider",
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    key: "policy",
    icon: privacyIcon,
    route: "/policy",
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: supportIcon,
    route: "/support",
  },
];

export { routes, bottomRoutes };
