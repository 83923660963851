import { Grid, Card } from "@mui/material";
import React from "react";
import MDBox from "components/MDBox";
import "./../../../../../src/pages/dashboard/components/statsCard/statsCard.css";

function StatsCardCompotent(props) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={3} className="good">
      <MDBox display="flex" alignItems="center" margin="12px" flexWrap="wrap">
        <Card
          className="cardStats"
          style={{
            background: `${props.colour}`,
            minHeight: "111px",
          }}
        >
          <div className="subCard">
            <div className="descriptionCard">
              <div className="iconBox">
                {/* {props.icon} */}
                <img src={props.icon} alt="" />
              </div>
              <div className="textBox" style={{ color: props.textColor }}>
                {props.text}
              </div>
            </div>
            <div className="quantity" style={{ color: props.textColor }}>
              {props.value}
            </div>
          </div>
        </Card>
      </MDBox>
    </Grid>
  );
}

export default StatsCardCompotent;
