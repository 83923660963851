import { Backdrop, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TLinkApi from "services/tlink.api";

function VerifyToken() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let params = new URL(document.location).searchParams;
  let token = params.get("token");

  const VerifyData = async () => {
    debugger;
    setIsLoading(true);
    try {
      const { status } = await fetch(TLinkApi.baseUrl + `/verify-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (status === 200) {
        setIsVerified(true);
        enqueueSnackbar("Verified", { variant: "success" });
        setIsLoading(false);
      } else {
        enqueueSnackbar("Verification failed", { variant: "error" });
        setIsLoading(false);
        setIsVerified(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data.message);
      enqueueSnackbar(err?.response.data.message, {
        variant: "error",
      });
      setIsVerified(false);
    }
  };

  useEffect(() => {
    VerifyData();
    // eslint-disable-next-line
  }, [token]);

  return (
    <>
      <PageLayout
        gradientBg={
          "linear-gradient(315.54deg, #FF4646 -5.94%, #FFCDCD 21.57%, #FFFFFF 58.36%)"
        }
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {isVerified ? (
          <MDBox
            mt={10}
            style={{ height: "100%", width: "100%" }}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <MDButton
              mt={10}
              variant="gradient"
              color="info"
              onClick={() => {
                navigate("/login");
              }}
            >
              Click here to Login
            </MDButton>
          </MDBox>
        ) : (
          <MDBox
            mt={10}
            style={{ height: "100%", width: "100%" }}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <a href="https://voqall.com/">
              <MDButton
                mt={10}
                variant="gradient"
                color="info"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                Contact Admin
              </MDButton>
            </a>
          </MDBox>
        )}
      </PageLayout>
    </>
  );
}

export default VerifyToken;
