import { Grid, Card } from "@mui/material";
import React from "react";
import "./../../../../../src/pages/dashboard/components/surveyCard/index.css";
import MDButton from "components/MDButton";
import timerIcon from "./../../../../assets/icons/timer.svg";
import pointsIcon from "./../../../../assets/icons/points2.svg";
import { useNavigate } from "react-router-dom";
import surveyIcon from "../../../../assets/images/surveyIcon.png";

function SurveyCard(props) {
  const navigate = useNavigate();

  function handleclick(surveyId) {
    navigate(`/survey?survey_id=${surveyId}`);
  }
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        className="cardSurvey"
        style={{
          flexWrap: "wrap",
          display: "flex",
          margin: "12px",
          alignItems: "flex-end",
        }}
      >
        <div
          className="imageBox"
          style={{
            background:
              "linear-gradient(315.54deg, #FF4646 -5.94%, #FFCDCD 21.57%, #FFCDCD 58.36%)",
          }}
        >
          <img src={surveyIcon} className="surveyIcon" alt="" />
          <div className="desc" style={{ marginTop: "42px" }}>
            <img src={timerIcon} className="timerIcon" alt="Time" />
            <div>
              0{parseFloat(props.loi).toFixed(2)}
              {"  "}min
            </div>
          </div>

          <div className="desc">
            <img src={pointsIcon} className="pointsIcon" alt="Points" />
            <div className="text" style={{ fontWeight: 600, fontSize: "15px" }}>
              {props.cpi}
            </div>
          </div>
        </div>
        <div className="survey-name-button-div">
          <div
            className="surveyName"
            style={{ fontWeight: 600, fontSize: "13px" }}
          >
            {props.name}
          </div>
          <MDButton
            color="primary"
            value={props.surveyId}
            className="surveyBtn"
            onClick={() => handleclick(props.surveyId)}
          >
            Take Survey
          </MDButton>
        </div>
      </Card>
    </Grid>
  );
}

export default SurveyCard;
