import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "./../../../components/MDBox";
import MDInput from "./../../../components/MDInput";
import MDButton from "./../../../components/MDButton";
import Grid from "@mui/material/Grid";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import "./index.css";

import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment/moment";

function UserDetailForm({ setIsEditable, list, getProfile }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      gender: "",
      dob: "",
      ethnicity: "",
      building: "",
      area: "",
      city: "",
      state: "",
      zipCode: "",
    },
  });

  let gender = watch("gender");
  let zip = watch("zipCode");
  let dob = watch("dob");

  useEffect(() => {
    if (Object.keys(list).length > 0) {
      setValue("firstName", list.firstName);
      setValue("lastName", list.lastName);
      setValue("emailId", list.emailId);
      setValue("gender", list.gender === null ? "" : list.gender);
      setValue(
        "dob",
        list.gender === null ? "" : moment(list?.dob).format("YYYY-MM-DD")
      );
      setValue("building", list.building);
      setValue("ethnicity", list.ethnicity === null ? "" : list.ethnicity);
      setValue("area", list.area);
      setValue("city", list.city);
      setValue("state", list.state);
      setValue("zipCode", list.zipCode);
    }
    // eslint-disable-next-line
  }, [list]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    let userId = localStorage.getItem("userId");

    try {
      await TLinkApi.put(`/user/profile/${userId}`, data);
      enqueueSnackbar("Updated", {
        variant: "success",
      });
      setIsLoading(false);
      setIsEditable(false);
      getProfile();
    } catch (e) {
      console.log(e?.message);
      enqueueSnackbar(e?.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <MDBox p={2} style={{ background: "rgba(238, 238, 238, 1)" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: "First Name is required!",
                }}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">
                      <span style={{ color: "red" }}>*</span> First Name
                    </label>
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      disabled
                      autoComplete="off"
                      variant="outlined"
                      error={errors?.firstName ? true : false}
                      helperText={
                        errors?.firstName ? errors.firstName.message : null
                      }
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: "Last Name is required!",
                }}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">
                      <span style={{ color: "red" }}>*</span> Last Name
                    </label>
                    <MDInput
                      style={{ color: "blue" }}
                      {...field}
                      fullWidth
                      required
                      disabled
                      autoComplete="off"
                      variant="outlined"
                      error={errors?.lastName ? true : false}
                      helperText={
                        errors?.lastName ? errors.lastName.message : null
                      }
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: "Gender is required!",
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <label className="labelStyle">
                      <span style={{ color: "red" }}>*</span> Gender
                    </label>
                    <FormControl
                      fullWidth
                      required
                      error={errors?.gender ? true : false}
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        sx={{ backgroundColor: "rgba(226, 226, 226, 1)" }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                      {errors?.gender && (
                        <FormHelperText>
                          {errors?.gender ? errors.gender.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="dob"
                control={control}
                rules={{
                  required: "Date of Birth is required!",
                }}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">
                      <span style={{ color: "red" }}>*</span> Date of Birth
                    </label>
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.dob ? true : false}
                      helperText={errors?.dob ? errors.dob.message : null}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="emailId"
                control={control}
                rules={{
                  required: "Email is required!",
                }}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">
                      <span style={{ color: "red" }}>*</span> Email Id
                    </label>
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      disabled
                      autoComplete="off"
                      variant="outlined"
                      error={errors?.emailId ? true : false}
                      helperText={
                        errors?.emailId ? errors.emailId.message : null
                      }
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="ethnicity"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <label className="labelStyle">Ethnicity</label>
                    <FormControl fullWidth>
                      <Select
                        value={value}
                        onChange={onChange}
                        sx={{ backgroundColor: "rgba(226, 226, 226, 1)" }}
                      >
                        <MenuItem value="White or Caucasian (non-Hispanic)">
                          White or Caucasian (non-Hispanic)
                        </MenuItem>
                        <MenuItem value="Black or African-American">
                          Black or African-American
                        </MenuItem>
                        <MenuItem value="Asian or Pacific Islander">
                          Asian or Pacific Islander
                        </MenuItem>
                        <MenuItem value="Hispanic or Latino">
                          Hispanic or Latino
                        </MenuItem>
                        <MenuItem value="American Indian or Alaska Native">
                          American Indian or Alaska Native
                        </MenuItem>
                        <MenuItem value="Mixed Racial Background">
                          Mixed Racial Background
                        </MenuItem>
                        <MenuItem value="Prefer not to answer">
                          Prefer not to answer
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="building"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">Apartment/Building</label>
                    <MDInput
                      {...field}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="area"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">Area</label>
                    <MDInput
                      {...field}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">City</label>
                    <MDInput
                      {...field}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">Province/State</label>
                    <MDInput
                      {...field}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="zipCode"
                control={control}
                rules={{
                  required: "Zip Code is required!",
                }}
                render={({ field }) => (
                  <>
                    <label className="labelStyle">
                      <span style={{ color: "red" }}>*</span> Zip Code
                    </label>
                    <MDInput
                      {...field}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      type="Number"
                      error={errors?.zipCode ? true : false}
                      helperText={
                        errors?.zipCode ? errors.zipCode.message : null
                      }
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox
          pt={4}
          px={2}
          pb={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="gradient"
            style={{ background: "rgba(255, 176, 176, 1)", color: "black" }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            style={{ background: "rgba(255, 51, 51, 1)", color: "white" }}
            type="submit"
            onClick={() => {
              if (zip === "" || gender === "" || dob === "") {
                enqueueSnackbar(
                  "Please update the highlighted fields before proceeding",
                  { variant: "error" }
                );
              }
            }}
          >
            Save
          </MDButton>
        </MDBox>
      </form>
    </>
  );
}

export default UserDetailForm;
