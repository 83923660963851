import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";

const ProfileSurvey = () => {
  const { currentUser } = useOutletContext();
  const [questionsdata, setQuestionsdata] = useState([]);
  const [unAnswered, setUnAnswered] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const QuestionType = {
    InputType: 1,
    InputDay: 2,
    InputDate: 3,
    SingleSelect: 4,
    MultipleSelect: 5,
  };

  let params = new URL(document.location).searchParams;
  let survey_id = params.get("survey_id");

  let getQuestion = async () => {
    setIsloading(true);
    var data = await TLinkApi.get(`/surveys/getSurveyQuestion/${survey_id}`);
    let payload = data.data.map((value, i) => {
      if (
        QuestionType.InputType === value.question_type ||
        QuestionType.InputDay === value.question_type ||
        QuestionType.InputDate === value.question_type
      ) {
        value.answer = "";
      } else if (
        QuestionType.SingleSelect === value.question_type ||
        QuestionType.MultipleSelect === value.question_type
      ) {
        value.answer = [];
      }
      return value;
    });
    setQuestionsdata(payload);
    setIsloading(false);
  };

  useEffect(() => {
    if (survey_id) {
      getQuestion();
    }
    // eslint-disable-next-line
  }, [survey_id]);

  const submitQuestions = async (userResponse) => {
    var answerData = {};
    answerData.userId = currentUser?.userid;
    answerData.surveyId = survey_id;
    var responses = [];

    for (var i = 0; i < userResponse.length; i++) {
      var response = {};
      response.id = userResponse[i].question_id;
      if (userResponse[i].answerText) {
        if (userResponse[i].answerText === "") {
          setUnAnswered(true);
          return;
        } else {
          response.answerText = userResponse[i].answerText;
        }
      } else {
        if (userResponse[i].answer.length <= 0) {
          setUnAnswered(true);
          return;
        } else {
          response.answerId = userResponse[i].answer;
        }
      }
      responses.push(response);
    }
    setIsloading(true);
    answerData.responses = responses;
    var { status } = await TLinkApi.post(`/surveys/submit`, answerData);
    if (status === 200) {
      enqueueSnackbar("survey submited successfully !", {
        variant: "success",
      });
      setIsloading(false);
      navigate("/points");
    } else {
      enqueueSnackbar("Opps somthing went wrong !", {
        variant: "error",
      });
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (unAnswered) {
      enqueueSnackbar("Please answer all questions.", {
        variant: "error",
      });
      setUnAnswered(false);
    }
  }, [unAnswered]);

  return (
    <MDBox pl={"5px"} mt={2}>
      <MDTypography
        variant="h5"
        py={2}
        fontWeight="light"
        mx={2}
        sx={{
          color: "dark",
          fontWeight: 500,
        }}
      >
        Answer the following questions and earn points.
      </MDTypography>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {questionsdata?.map((ques, i) => {
        let returnValue;
        if (
          ques.question_type === QuestionType.InputType ||
          QuestionType.InputDay === ques.question_type ||
          QuestionType.InputDate === ques.question_type
        ) {
          returnValue = (
            <MDInput
              style={{ color: "primary" }}
              fullWidth
              required
              autoComplete="off"
              variant="outlined"
              onChange={(e) => {
                let payload = [...questionsdata];
                payload[i].answerText = e.target.value;
                setQuestionsdata(payload);
              }}
            />
          );
        } else if (ques.question_type === QuestionType.SingleSelect) {
          returnValue = (
            <FormGroup row>
              {ques.question_option.map((option, idx) => {
                return (
                  <FormControlLabel
                    value="start"
                    checked={ques.answer.includes(option.option_id)}
                    key={idx}
                    onChange={(e) => {
                      let payload = [...questionsdata];
                      payload[i].answer = [option.option_id];
                      setQuestionsdata(payload);
                    }}
                    name={option.option_text}
                    control={<Radio color="primary" />}
                    label={option.option_text}
                    sx={{ minWidth: "30%" }}
                  />
                );
              })}{" "}
            </FormGroup>
          );
        } else if (ques.question_type === QuestionType.MultipleSelect) {
          returnValue = (
            <FormGroup row>
              {ques.question_option.map((option, idx) => {
                return (
                  <FormControlLabel
                    key={idx}
                    sx={{ minWidth: "45%" }}
                    control={
                      <Checkbox
                        checked={ques.answer.includes(option.option_id)}
                        onChange={(e) => {
                          let payload = [...questionsdata];
                          if (!e.target.checked) {
                            payload[i].answer = payload[i].answer.filter(
                              (val) => val !== option.option_id
                            );
                          } else {
                            payload[i].answer.push(option.option_id);
                          }
                          setQuestionsdata(payload);
                        }}
                        name={option.option_text}
                      />
                    }
                    label={option.option_text}
                  />
                );
              })}
            </FormGroup>
          );
        }

        return (
          <Accordion
            expanded={true}
            key={i}
            style={{
              margin: "12px",
              borderRadius: "12px",
              border: "none",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
            }}
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary>
              <MDTypography
                fontWeight="light"
                sx={{ fontWeight: 400 }}
                variant="h6"
              >
                {`${ques.question_text}`}
              </MDTypography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0px 28px 12px 42px",
              }}
            >
              {returnValue}
            </AccordionDetails>
          </Accordion>
        );
      })}

      {!isLoading && (
        <MDBox sx={{ display: "flex", justifyContent: "end" }} mt={4} pb={2}>
          <MDButton
            size="small"
            color="primary"
            variant="outlined"
            style={{
              textTransform: "capitalize",
              margin: "8px 0px",
            }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Cancel
          </MDButton>

          <MDButton
            color="primary"
            variant="contained"
            size="small"
            style={{
              textTransform: "capitalize",
              margin: "8px 16px",
            }}
            onClick={() => {
              submitQuestions(questionsdata);
            }}
          >
            Submit
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
};

export default ProfileSurvey;
