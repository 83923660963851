import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import "./Details.css";
function DetailsPoints({ summary }) {
  return (
    <div className="FullCard">
      <div>Points Detail</div>
      <div className="Card1">
        <div className="Total">
          <div>Total:</div>
          <div>{summary.totalPoints ? summary.totalPoints : 0}</div>
        </div>
        <div className="Total">
          <div>Redeemed:</div>
          <div>{summary.pointsRedeemed ? summary.totalPoints : 0}</div>
        </div>
        <div className="Total">
          <div>Available:</div>
          <div>
            {summary && summary.totalPoints && summary.pointsRedeemed
              ? summary?.totalPoints - summary?.pointsRedeemed
              : 0}
          </div>
        </div>
      </div>

      <div>Selected Reward</div>
      <div className="ShowCase">
        <div style={{ textAlign: "center" }}>No Rewards Selected</div>
      </div>
      <MDBox
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        alignItems="center"
        width="100%"
        gap="10px"
      >
        <MDButton
          variant="gradient"
          style={{
            background: "rgba(255, 176, 176, 1)",
            color: "black",
          }}
          onClick={() => {}}
        >
          &nbsp;cancel
        </MDButton>
        <MDButton
          variant="gradient"
          style={{
            background: "rgba(255, 51, 51, 1)",
            color: "white",
          }}
          type="submit"
        >
          Proceed
        </MDButton>
      </MDBox>
    </div>
  );
}

export default DetailsPoints;
