import { Grid } from "@mui/material";
import React from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

function UserDetailsTable({ setIsEditable, list }) {
  const navigate = useNavigate();

  const handleEdit = () => {
    setIsEditable(true);
  };

  const style = {
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineheight: "20px",
    color: "#757575",
    marginBottom: "30px",
  };

  return (
    <MDBox>
      <Grid container spacing={2} px={10} bgcolor="rgba(238, 238, 238, 1)">
        <Grid item xs={12} md={6} style={style}>
          First Name
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.firstName}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Last Name
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.lastName}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Gender
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.gender}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Date of Birth
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {moment(list?.dob).format("YYYY-MM-DD")}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Email Id
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
              overflowWrap: "break-word",
            }}
          >
            {list?.emailId}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Ethnicity
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.ethnicity}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Apartment/Building
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.building}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Area
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.area}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          City
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.city}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Provinence/State
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.state}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} style={style}>
          Zip Code
          <MDBox
            style={{
              color: "rgba(51, 51, 51, 1)",
            }}
          >
            {list?.zipCode}
          </MDBox>
        </Grid>
      </Grid>
      <MDBox
        py={5}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        gap="15px"
      >
        <MDButton
          variant="gradient"
          style={{ background: "rgba(255, 176, 176, 1)", color: "black" }}
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          style={{ background: "rgba(255, 51, 51, 1)", color: "white" }}
          onClick={handleEdit}
        >
          Edit
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default UserDetailsTable;
