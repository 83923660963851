import { Backdrop, CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import TLinkApi from "services/tlink.api";
import illustration from "./../../assets/images/illustration1.png";
import logo from "./../../assets/icons/logo-big.svg";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

function ForgotPassword() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { email: "" },
  });

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const save = async (data) => {
    try {
      const { status } = await TLinkApi.post("/forgetpassword/Sendmail", data);
      if (status === 200) {
        enqueueSnackbar("Please check your email and reset the password ", {
          variant: "success",
        });
        setIsLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <PageLayout
        gradientBg={
          "linear-gradient(315.54deg, #FF4646 -5.94%, #FFCDCD 21.57%, #FFFFFF 58.36%)"
        }
      >
        <MDBox px={1} width="100%" height="100vh" mx="auto" position="relative">
          <a href="https://voqall.com/" style={{ cursor: "pointer" }}>
            <img
              src={logo}
              style={{ height: "24px", position: "absolute", margin: "32px" }}
              alt="logo"
            />
          </a>

          <Grid
            container
            spacing={1}
            justifyContent="space-around"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={11} sm={9} md={5} lg={5} xl={3.5}>
              <MDBox mb={1} textAlign="center">
                <MDTypography
                  variant="h2"
                  fontWeight="medium"
                  color="text"
                  mt={1}
                  mb={2}
                  sx={{
                    color: "black",
                  }}
                >
                  Email Confirmation
                </MDTypography>
                <MDTypography
                  variant="h6"
                  fontWeight="light"
                  color="text"
                  sx={{ color: "#333333" }}
                  mt={1}
                  mb={3}
                >
                  Enter your email to verify
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <form onSubmit={handleSubmit(save)} noValidate>
                  <MDBox mb={2}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type="email"
                          {...register("email", {
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                          variant="outlined"
                          placeholder="Input Email"
                          error={errors?.email ? true : false}
                          helperText={
                            errors?.email ? errors.email.message : null
                          }
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox mt={2} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                    >
                      Verify
                    </MDButton>
                  </MDBox>
                  <MDBox
                    mt={2}
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      <strong style={{ fontSize: "14px", color: "red" }}>
                        Go back to Login
                      </strong>
                    </p>
                  </MDBox>
                </form>
              </MDBox>
            </Grid>
            <Grid
              item
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block;",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <img
                src={illustration}
                style={{ maxHeight: "95vh" }}
                alt="logo"
              />
            </Grid>
          </Grid>
        </MDBox>
      </PageLayout>
    </>
  );
}

export default ForgotPassword;
