import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import TLinkApi from "services/tlink.api";
import illustration from "./../../assets/images/illustration1.png";
import logo from "./../../assets/icons/logo-big.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

function ConfirmPassword() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { password: "", confirmPassword: "" },
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { enqueueSnackbar } = useSnackbar();
  let params = new URL(document.location).searchParams;
  let token = params.get("token");

  const save = async (data) => {
    if (data.password !== data.confirmPassword) {
      enqueueSnackbar("Password does not match Confirm password", {
        variant: "error",
      });
    } else {
      setIsLoading(true);
      try {
        const { status } = await fetch(TLinkApi.baseUrl + `/UpdatePassword`, {
          body: JSON.stringify({ password: data.password }),
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (status === 200) {
          enqueueSnackbar("Password Reset Done", {
            variant: "success",
          });
          navigate("/login");
          setIsLoading(false);
        }
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
        setIsLoading(false);
        // alert("Login failed. Please check your credentials!");
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout
        gradientBg={
          "linear-gradient(315.54deg, #FF4646 -5.94%, #FFCDCD 21.57%, #FFFFFF 58.36%)"
        }
      >
        <img
          src={logo}
          style={{ height: "24px", position: "absolute", margin: "32px" }}
          alt="logo"
        />

        <MDBox
          position="absolute"
          width="30%"
          height="auto"
          left="0"
          top="0"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) => logo && `url(${logo})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />

        <MDBox px={1} width="100%" height="100vh" mx="auto" position="relative">
          <Grid
            container
            spacing={1}
            justifyContent="space-around"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={11} sm={9} md={5} lg={5} xl={3.5}>
              <MDBox mb={1} textAlign="center">
                <MDTypography
                  variant="h6"
                  fontWeight="light"
                  color="text"
                  sx={{ color: "#333333" }}
                  mt={1}
                >
                  Enter your Password
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <form onSubmit={handleSubmit(save)} noValidate>
                  <MDBox mb={2}>
                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: "Password is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password *"
                          variant="outlined"
                          error={errors?.password ? true : false}
                          helperText={
                            errors?.password ? errors.password.message : null
                          }
                          {...register("password", {
                            required: "Password is required!",
                            minLength: {
                              value: 8,
                              message:
                                "Must contain at least one uppercase letter. Must contain at least one lowercase letter. Must contain at least one number. Must contain at least one special character.",
                            },
                            validate: (value) => {
                              return (
                                [
                                  /[a-z]/,
                                  /[A-Z]/,
                                  /[0-9]/,
                                  /[^a-zA-Z0-9]/,
                                ].every((pattern) => pattern.test(value)) ||
                                "Must contain at least one uppercase letter. Must contain at least one lowercase letter. Must contain at least one number. Must contain at least one special character."
                              );
                            },
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      rules={{
                        required: "Confirm Password is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password *"
                          variant="outlined"
                          error={errors?.confirmPassword ? true : false}
                          helperText={
                            errors?.confirmPassword
                              ? errors.confirmPassword.message
                              : null
                          }
                          {...register("confirmPassword", {
                            required: "Confirm Password is required!",
                            minLength: {
                              value: 8,
                              message:
                                "Must contain at least one uppercase letter. Must contain at least one lowercase letter. Must contain at least one number. Must contain at least one special character.",
                            },
                            validate: (value) => {
                              return (
                                [
                                  /[a-z]/,
                                  /[A-Z]/,
                                  /[0-9]/,
                                  /[^a-zA-Z0-9]/,
                                ].every((pattern) => pattern.test(value)) ||
                                "Must contain at least one uppercase letter. Must contain at least one lowercase letter. Must contain at least one number. Must contain at least one special character."
                              );
                            },
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox mt={2} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Grid>
            <Grid
              item
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block;",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <img
                src={illustration}
                style={{ maxHeight: "95vh" }}
                alt="logo"
              />
            </Grid>
          </Grid>
        </MDBox>
      </PageLayout>
    </>
  );
}

export default ConfirmPassword;
