import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

// import Api from "services/api";

const LiveSurveys = (userId) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSurveys = async () => {
    setIsLoading(true);
    try {
      const data = await TLinkApi.get(`/surveys/live/Survey`);
      setList(data?.data?.LivesurveysData);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userId) {
      getSurveys();
    }
    // eslint-disable-next-line
  }, [userId]);
  return { list, isLoading };
};

const SummaryData = () => {
  const [list, setList] = useState({});

  const GetUserSummary = async () => {
    try {
      const data = await TLinkApi.get(`/user/summary`);
      if (data) {
        setList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    GetUserSummary();
    // eslint-disable-next-line
  }, []);

  return list;
};

const SurveyHistory = (userId) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSurveysHistory = async () => {
    setIsLoading(true);
    try {
      const data = await TLinkApi.get(`/surveys/history/${userId}`);
      setList(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      getSurveysHistory();
    }
    // eslint-disable-next-line
  }, [userId]);

  return { list, isLoading };
};

export { LiveSurveys, SurveyHistory, SummaryData };
