import { Backdrop, CircularProgress, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import StatsCardCompotent from "pages/dashboard/components/statsCard/statsCard";
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import surveyIcon from "../../../src/assets/icons/survey.svg";
import pointsIcon from "../../../src/assets/icons/points.svg";
import timeIcon from "../../../src/assets/icons/timer.svg";
import statusIcon from "../../../src/assets/icons/status.svg";
import { SurveyHistory } from "data/survey";
import TotalPointsIcon from "../../../src/assets/icons/totalPoints.svg";
import TotalSurveyIcon from "../../../src/assets/icons/totalSurveys.svg";
import SurveyCompletedIcon from "../../../src/assets/icons/surveyCompleted.svg";
import SurveyType1Icon from "../../../src/assets/icons/surveyType1.svg";
import { SummaryData } from "data/survey";
import { useOutletContext } from "react-router-dom";

function PointsDetails() {
  const { currentUser } = useOutletContext();
  var summary = SummaryData();

  const [pageSize, setPageSize] = useState(10);
  const { list, isLoading } = SurveyHistory(currentUser?.userid);

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 7,
      bottom: params.isLastVisible ? 0 : 7,
    };
  }, []);

  const customHeader = (name, icon) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <img src={icon} alt="" />
        {name}
      </div>
    );
  };

  const projectColumns = [
    {
      field: "surveyType",
      headerName: "Survey Type",
      renderHeader: () => {
        return customHeader("Survey Type", surveyIcon);
      },
      renderCell: () => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "12px 0",
              gap: "10px",
            }}
          >
            <img src={SurveyType1Icon} alt="" />
            <div>Profile</div>
          </div>
        );
      },
      minWidth: 150,
      flex: 1,
    },

    {
      field: "cpi",
      headerName: "Points",
      renderHeader: () => {
        return customHeader("Points", pointsIcon);
      },
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "loi",
      renderHeader: () => {
        return customHeader("Time Required", timeIcon);
      },
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "surveyStatus",
      renderHeader: () => {
        return customHeader("Status", statusIcon);
      },
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: (row) => {
        return (
          <div style={{ color: row.value === "complete" ? "#3AA76D" : "" }}>
            {row.value}
          </div>
        );
      },
    },
  ];

  return (
    <MDBox>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1} pl={"5px"} mt={2}>
        <StatsCardCompotent
          colour={
            "linear-gradient(286.07deg, #FF8A8A 0%, rgba(255, 215, 215, 0.65) 100%)"
          }
          textColor="#FF3333"
          text={"Total Points"}
          icon={TotalPointsIcon}
          value={summary?.totalPoints ? summary.totalPoints : 0}
        />

        <StatsCardCompotent
          colour={
            "linear-gradient(285.75deg, #FFAA83 3.97%, rgba(253, 222, 208, 0.65) 96.49%)"
          }
          text={"Total Surveys"}
          textColor="#ED6E33"
          icon={TotalSurveyIcon}
          value={summary?.totatSurveys ? summary?.totatSurveys : 0}
        />
        <StatsCardCompotent
          colour={"linear-gradient(286.69deg, #FFD582 -0.93%, #FFF5E1 100%)"}
          text={"Surveys Completed"}
          textColor="#FFA800"
          icon={SurveyCompletedIcon}
          value={summary?.surveysCompleted ? summary?.surveysCompleted : 0}
        />
      </Grid>
      <Divider mt={1} />

      <MDTypography
        variant="h5"
        mt={1}
        fontWeight="light"
        mx={3}
        sx={{
          color: "black",
          fontWeight: 500,
        }}
      >
        Survey History
      </MDTypography>

      <DataGrid
        rows={list.length > 0 ? list : []}
        columns={projectColumns}
        getRowId={(row) => row.id}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowSpacing={getRowSpacing}
        disableSelectionOnClick
        rowHeight={64}
        sx={{
          minHeight: 700,
          marginTop: "18px",
          border: "none",
          ".MuiDataGrid-columnHeaders.css-z8fhq1-MuiDataGrid-columnHeaders": {
            fontSize: "15px",
            border: "none",
            margin: "0 24px",
            fontWeight: 500,
          },
          ".MuiDataGrid-virtualScrollerRenderZone.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone":
            {
              fontSize: "14px",
              fontWeight: 400,
            },

          ".MuiDataGrid-row": {
            backgroundColor: "#F6F6F6",
            borderRadius: "12px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
            margin: "12px auto",
            padding: "0 0 0 12px",
            width: "96%",
          },

          ".MuiDataGrid-cell": {
            border: "none",
          },
        }}
      />
    </MDBox>
  );
}

export default PointsDetails;
