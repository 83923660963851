import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

function GetProfileData() {
  const [list, setList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getProfile = async () => {
    setIsLoading(true);
    try {
      const data = await TLinkApi.get("/user/profile");
      setList(data.data.profileData);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  return { list, isLoading, getProfile };
}
export default GetProfileData;
