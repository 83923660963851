import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Auth from "services/auth";
import PrincipalState from "services/principal.state";
import Sidenav from "examples/Sidenav";
import { routes, bottomRoutes } from "routes";
import { useMaterialUIController } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Footer from "examples/Footer";

const AuthOutlet = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const [currentUser, setCurrentUser] = useState(null);

  const authorize = async () => {
    try {
      await Auth.authorize();
      let data = PrincipalState.getIdentity();
      if (data) {
        setCurrentUser(data.data);
        localStorage.setItem("userId", data.data.userid);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    authorize();
  }, []);

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={
          (transparentSidenav && !darkMode) || whiteSidenav
            ? brandDark
            : brandWhite
        }
        brandName="Material Dashboard 2"
        routes={routes}
        bottomRoutes={bottomRoutes}
        currentUser={currentUser}
      />
      <DashboardLayout>
        <DashboardNavbar currentUser={currentUser} />
        <div
          className="header"
          style={{
            minHeight: "89vh",
            width: "100%",
            background: "#FFFFFF",
            borderRadius: "10px",
          }}
        >
          <Outlet context={{ currentUser, setCurrentUser }} />
        </div>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default AuthOutlet;
