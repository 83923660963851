import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import Voqall from "assets/images/logos/Voqall_Logo.png";
import privacyIcon from "assets/icons/privacy.svg";
import supportIcon from "assets/icons/support.svg";
import "./index.css";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { Close } from "@mui/icons-material";
import { Avatar } from "@mui/material";

function Sidenav({
  color,
  brand,
  brandName,
  routes,
  bottomRoutes,
  currentUser,
  ...rest
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const location = useLocation();
  const collapseName = window.location.pathname.split("/")[1];
  const [sideNavMin, setSideNavMin] = useState(false);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth > 1200);
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
    // eslint-disable-next-line
  }, [dispatch, location]);

  function updateSize() {
    if (window.innerWidth < 1200) {
      setSideNavMin(true);
    } else if (window.innerWidth > 1200) {
      setSideNavMin(false);
    }
  }

  window.addEventListener("resize", updateSize);

  useEffect(() => {
    updateSize();
    // eslint-disable-next-line
  }, []);

  const renderRoutes = routes.map(
    ({ type, name, icon, noCollapse, key, href, route, activeIcon }) => {
      let returnValue;
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              sideNavMin={sideNavMin}
              root={false}
              icon={icon}
              activeIcon={activeIcon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              sideNavMin={sideNavMin}
              activeIcon={activeIcon}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center" mb={5}>
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Close sx={{ fontWeight: "bold" }}>close</Close>
          </MDTypography>
        </MDBox>

        <MDBox display="flex" alignItems="center" justifyContent="center">
          <a href="https://voqall.com/" style={{ cursor: "pointer" }}>
            <MDBox
              width="100%"
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <MDBox
                component="img"
                src={Voqall}
                alt="Voqall"
                width="auto"
                height="24px"
              />
            </MDBox>
          </a>
        </MDBox>
      </MDBox>

      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mb={6}
      >
        <Avatar sx={{ bgcolor: "red", width: 56, height: 56 }} />

        <span className="userName" style={{ color: sideNavMin ? "white" : "" }}>
          {currentUser && currentUser.firstname && currentUser.lastname
            ? currentUser?.firstname + " " + currentUser?.lastname
            : ""}
        </span>
        <span className="userMail" style={{ color: sideNavMin ? "white" : "" }}>
          {currentUser?.email === undefined ? "" : currentUser?.email}
        </span>
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="center"></MDBox>

      <List>{renderRoutes}</List>

      <MDBox pb={3} mt="auto" sx={{ width: "100%" }}>
        <Divider />
        <List>
          <SidenavCollapse
            name={"Privacy Policy"}
            root={false}
            sideNavMin={sideNavMin}
            icon={privacyIcon}
            active={"policy" === collapseName}
            onClick={() => {
              window.open("https://voqall.com/privacy-policy/");
            }}
          />
        </List>
        <List>
          <SidenavCollapse
            name={"Support"}
            root={false}
            sideNavMin={sideNavMin}
            icon={supportIcon}
            active={"support" === collapseName}
            onClick={() => {
              window.open("https://voqall.com/contact/");
            }}
          />
        </List>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
